// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CompleteInventoryTutorialForm from '@shared/modules/Inventory/forms/CompleteInventoryTutorialForm';

const useCompleteInventoryTutorialMutation = ({
  completeInventoryTutorialForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      completeInventoryTutorialForm: CompleteInventoryTutorialForm.toForm(
        completeInventoryTutorialForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCompleteInventoryTutorialMutation.mutation,
    variables: {
      completeInventoryTutorialForm: CompleteInventoryTutorialForm.toMutation(
        form.values.completeInventoryTutorialForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useCompleteInventoryTutorialMutation.mutation = gql`
  mutation useCompleteInventoryTutorialMutation($completeInventoryTutorialForm: CompleteInventoryTutorialForm!) {
    response: completeInventoryTutorial(completeInventoryTutorialForm: $completeInventoryTutorialForm) {
      ${gql.errors}
      inventory {
        id
      }
    }
  }
`;

export default useCompleteInventoryTutorialMutation;
