/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const MultipleChoiceOptionButton = Styled.Touchable`
  flex: 1;
  height: ${(props) => `${(props as any).height}px`};
  border-width: 1px;
  border-color: ${(props) => ((props as any).isSelected ? colors.orange.status : colors.gray.border)};
  border-radius: 8px;
  justify-content: center;
  background-color: ${(props) => ((props as any).isSelected ? colors.orange.accent : colors.white)};
`;

const MultipleChoiceOptionText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.black};
  padding-horizontal: 12px;
`;

const MultipleChoiceOption = ({children, height, isSelected, onPress}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <MultipleChoiceOptionButton height={height} isSelected={isSelected} onPress={onPress}>
      <MultipleChoiceOptionText>{children}</MultipleChoiceOptionText>
    </MultipleChoiceOptionButton>
  );
};

export default MultipleChoiceOption;
