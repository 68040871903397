/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

// App
import Field from 'components/Field';

const Container = Styled.View`
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 8px;
  padding-horizontal: 16px;
`;

const QuestionText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.black};
`;

const StarRatingContainer = Styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StarDescription = Styled.H8`
  ${fontWeight(700)}
  color: ${colors.orange.status};
  padding-horizontal: 8px;
`;

const StarIconContainer = Styled.Touchable`
  padding-horizontal: 4px;
`;

const InventoryReviewFields = ({form}: any) => {
  return (
    <Container>
      <Space height={20} />
      <QuestionText>How would you describe this virtual walkthrough process?</QuestionText>
      <Space height={20} />
      <StarRatingContainer>
        <StarDescription>Difficult</StarDescription>
        {[1, 2, 3, 4, 5].map((value) => (
          <StarIconContainer
            onPress={() => form.setFieldValue('inventoryReviewForm.rating', value)}
            key={value}
          >
            <Icon
              color={
                form.values.inventoryReviewForm.rating >= value
                  ? colors.orange.status
                  : colors.gray.border
              }
              size={16}
              source={Icon.Star}
            />
          </StarIconContainer>
        ))}
        <StarDescription>Easy</StarDescription>
        <Space width={8} />
      </StarRatingContainer>
      <Space height={20} />
      <Field
        {...form}
        name={'inventoryReviewForm.comment'}
        input={{
          placeholder: 'Leave any comment.',
          multiline: true,
          style: {minHeight: 90, backgroundColor: colors.gray.background},
        }}
      />
      <Space height={16} />
    </Container>
  );
};

export default InventoryReviewFields;
