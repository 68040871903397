/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, FileDragInput, Modal, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useQuery} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import DeleteAttachmentForm from '@shared/modules/File/forms/DeleteAttachmentForm';
import UploadFileForm from '@shared/modules/File/forms/UploadFileForm';
import useDeleteAttachmentForm from '@shared/modules/File/hooks/useDeleteAttachmentForm';
import useUploadAttachmentForm from '@shared/modules/File/hooks/useUploadAttachmentForm';
import AppModal from 'components/Modal';

const Container = Styled.View`
  background-color: ${colors.white};
  border-radius: 8px;
  border-width: 1px;
  border-color: ${colors.gray.border};
`;

const AddPhotoButton = Styled.Touchable`
  height: 80px;
  width: 80px;
  background-color: ${colors.blue.accent};
  border-radius: 4px;
  align-items: center;
  justify-content: center;
`;

const Touchable = Styled.Touchable`
`;

const AttachmentContainer = Styled.View`
  flex-direction: row;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: ${colors.gray.tertiary};
  width: 80px;
  height: 80px;
`;

const Image = Styled.Image`
  height: inherit;
  width: inherit;
`;

const AddPhotoButtonText = Styled.H8`
  font-size: 10px;
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const Overlay = Styled.View`
  flex: 1;
  background-color: ${colors.alpha(colors.black, 0.7)};
`;

const Wrapper = Styled.View`
  flex: 1;
`;

const Header = Styled.View`
  height: 60px;
  padding-horizontal: 12px;
  align-items: flex-end;
  justify-content: center;
`;

const DeletePhotoButton = Styled.Touchable`
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: ${colors.blue.accent};
  position: absolute;
  top: 3px;
  right: 3px;
  align-items: center;
  justify-content: center;
`;

const DeleteModalTitle = Styled.H6`
  color: ${colors.black};
  ${fontWeight(700)}
`;

const DeleteModalMessage = Styled.H7`
  color: ${colors.black};
  ${fontWeight(500)}
`;

const DeleteModalActions = Styled.View`
  flex-direction: row;
`;

const DeleteModalActionButton = Styled.Button`
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-vertical: 14;
  border-radius: 100px;
  background-color: ${(props) => (props as any).background}
`;

const DeleteModalActionButtonText = Styled.H6`
  ${fontWeight(700)}
  color: ${(props) => (props as any).color};
`;

const AttachmentModal = ({isOpen, attachment, handleClose}: any) => {
  return (
    <Modal.Content isOpen={isOpen} onClose={handleClose}>
      <Overlay pointerEvents={'box-none'}>
        <Header>
          <Touchable onPress={handleClose}>
            <Icon color={colors.white} size={20} source={Icon.Times} />
          </Touchable>
        </Header>
        <Wrapper>
          <Image
            resizeMode={'contain'}
            source={{uri: attachment.file.downloadUrl}}
            accessibilityLabel={attachment.file.name}
            style={{flex: 1}}
          />
        </Wrapper>
      </Overlay>
    </Modal.Content>
  );
};

const DeleteAttachmentModal = ({isOpen, attachment, onSuccess, handleClose}: any) => {
  const {submitting, handleSubmit} = useDeleteAttachmentForm({
    deleteAttachmentForm: DeleteAttachmentForm.new({
      attachmentId: attachment.id,
    }),
    onSuccess: () => {
      onSuccess();
      handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <AppModal isOpen={isOpen} onClose={handleClose}>
      <Icon color={colors.red.warning} size={20} source={Icon.Trash} />
      <Space height={16} />
      <DeleteModalTitle>Delete photo?</DeleteModalTitle>
      <Space height={28} />
      <DeleteModalMessage>
        This photo will be deleted. This action cannot be undone.
      </DeleteModalMessage>
      <Space height={32} />
      <DeleteModalActions>
        <DeleteModalActionButton
          onPress={handleSubmit}
          background={colors.gray.border}
          disabled={submitting}
        >
          <DeleteModalActionButtonText color={colors.gray.secondary}>
            Delete
          </DeleteModalActionButtonText>
        </DeleteModalActionButton>
        <Space width={16} />
        <DeleteModalActionButton
          onPress={handleClose}
          background={colors.orange.status}
          disabled={submitting}
        >
          <DeleteModalActionButtonText color={colors.white}>Keep</DeleteModalActionButtonText>
        </DeleteModalActionButton>
      </DeleteModalActions>
    </AppModal>
  );
};

const AttachmentItem = ({attachment, refetch}: any) => {
  const imageViewerModal = useModal();
  const deleteAttachmentModal = useModal();

  return (
    <React.Fragment>
      <Touchable onPress={imageViewerModal.handleOpen}>
        <AttachmentContainer>
          <Image
            resizeMode={'cover'}
            source={{uri: attachment.file.downloadUrl}}
            accessibilityLabel={attachment.file.name}
          />
          <DeletePhotoButton onPress={deleteAttachmentModal.handleOpen}>
            <Icon color={colors.gray.primary} size={11} source={Icon.Times} />
          </DeletePhotoButton>
        </AttachmentContainer>
      </Touchable>
      <AttachmentModal
        isOpen={imageViewerModal.isOpen}
        attachment={attachment}
        handleClose={imageViewerModal.handleClose}
      />
      <DeleteAttachmentModal
        isOpen={deleteAttachmentModal.isOpen}
        attachment={attachment}
        onSuccess={refetch}
        handleClose={deleteAttachmentModal.handleClose}
      />
    </React.Fragment>
  );
};

const Indicator = Styled.Loading`
`;

const AttachmentLoading = () => {
  return (
    <AttachmentContainer>
      <Indicator size={'small'} color={colors.white} />
    </AttachmentContainer>
  );
};

const PhotoCollectionContent = ({
  room,
  viewer,
  refetch,
  onAddPhotoPress,
  onUploadStart,
  onUploadSuccess,
  onUploadError,
}: any) => {
  const uploadFileForm = UploadFileForm.new({
    organizationId: room.inventory.project.organization.id,
    creatorId: viewer.id,
    projectId: room.inventory.project.id,
    roomId: room.id,
    inventoryId: room.inventory.id,
    // @ts-expect-error TS(2322): Type '"ROOM_PHOTO"' is not assignable to type 'Att... Remove this comment to see the full error message
    attachmentCategoryKinds: ['ROOM_PHOTO'],
  });
  const {form, submitting, handleSubmit} = useUploadAttachmentForm({
    uploadFileForm,
    onSuccess: () => {
      refetch();
      onUploadSuccess();
    },
    onError: (error: any) => {
      console.log(error);
      onUploadError();
    },
  });

  return (
    <Container>
      <ScrollView horizontal contentContainerStyle={{padding: 6}} style={{flex: 1}}>
        <FileDragInput
          onFilesChange={(files) => {
            const file = files[0];
            form.setFieldValue(`uploadFileForm.requestUploadFileForm.mimetype`, file.type);
            form.setFieldValue(
              `uploadFileForm.requestUploadFileForm.filename`,
              UploadFileForm.formatName(file.name),
            );
            form.setFieldValue(`uploadFileForm.file`, file);
            onUploadStart();
            handleSubmit();
          }}
          accept={'image/*'}
          dropzoneOptions={{noClick: true, noKeyboard: true}}
        >
          {({isDragActive, handleOpen}) => {
            return (
              <AddPhotoButton onPress={() => onAddPhotoPress(handleOpen)} disabled={submitting}>
                <Icon source={Icon.Camera} size={16} color={colors.blue.interactive} />
                <Space height={4} />
                <AddPhotoButtonText>Add photo</AddPhotoButtonText>
              </AddPhotoButton>
            );
          }}
        </FileDragInput>
        <Space width={10} />
        {submitting && (
          <React.Fragment>
            <AttachmentLoading />
            <Space width={10} />
          </React.Fragment>
        )}
        {room.filteredAttachments.map((attachment: any, index: any) => (
          <React.Fragment key={index}>
            <AttachmentItem key={attachment.id} attachment={attachment} refetch={refetch} />
            <Space width={10} />
          </React.Fragment>
        ))}
      </ScrollView>
    </Container>
  );
};

const PhotoCollection = ({
  room,
  onAddPhotoPress,
  onUploadStart,
  onUploadSuccess,
  onUploadError,
}: any) => {
  const {data, refetch} = useQuery(PhotoCollection.query, {
    fetchPolicy: 'network-only',
    variables: {
      roomUuid: room.uuid,
    },
  });

  return data ? (
    <PhotoCollectionContent
      room={data.room}
      viewer={data.viewer}
      refetch={refetch}
      onAddPhotoPress={onAddPhotoPress}
      onUploadStart={onUploadStart}
      onUploadSuccess={onUploadSuccess}
      onUploadError={onUploadError}
    />
  ) : null;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PhotoCollection.query = gql`
  query PhotoCollection($roomUuid: String!) {
    ${gql.query}
    viewer {
      id
    }
    room(uuid: $roomUuid) {
      id
      name
      filteredAttachments(attachmentCategoryKinds: ["ROOM_PHOTO"]) {
        id
        file {
          id
          downloadUrl
          name
          thumbnailFile {
            id
            downloadUrl
          }
        }
      }
      inventory {
        id
        project {
          id
          organization {
            id
          }
        }
      }
    }
  }
`;

PhotoCollection.fragment = gql`
  fragment PhotoCollection on Room {
    id
    uuid
  }
`;

export default PhotoCollection;
