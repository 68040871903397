/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Button = Styled.Touchable`
  background-color: ${(props) => ((props as any).disabled ? colors.gray.border : colors.blue.interactive)};
  padding: 20px;
  border-radius: 100px;
  align-items: center;
`;

const ButtonText = Styled.H6`
  color: ${(props) => (props as any).color};
  ${fontWeight(700)}
  text-align: center;
`;

const ActivityIndicator = Styled.Loading`
`;

const LargeButton = ({onPress, children, color, disabled, submitting}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Button backgroundColor={color} onPress={onPress} disabled={disabled}>
      {submitting ? (
        <ActivityIndicator color={colors.white} style={{height: 22}} />
      ) : (
        <React.Fragment>{children}</React.Fragment>
      )}
    </Button>
  );
};

LargeButton.Text = ButtonText;

export default LargeButton;
