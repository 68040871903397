import React from 'react';

// Libraries
import {PageContext} from '@supermove/analytics';
import {useActiveRoute, useTrackPageview} from '@supermove/hooks';

const RouterWrapper = ({navigation, children}: any) => {
  const {route} = useActiveRoute({navigation});
  // @ts-expect-error TS(2339): Property 'routeName' does not exist on type 'Navig... Remove this comment to see the full error message
  const {routeName} = route;
  useTrackPageview({routeName});
  return <PageContext.Provider value={{pageName: routeName}}>{children}</PageContext.Provider>;
};

export default RouterWrapper;
