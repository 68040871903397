/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigation, useQuery} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import HelpBubble from 'components/HelpBubble';
import LargeButton from 'components/LargeButton';
import Line from 'components/Line';
import ProgressBar from 'components/ProgressBar';
import DoneRecordingConfirmationModal from 'modules/Inventory/Room/List/components/DoneRecordingConfirmationModal';
import RoomList from 'modules/Inventory/Room/List/components/RoomList';
import InventoryHeader from 'modules/Inventory/components/InventoryHeader';

const ContentContainer = Styled(ScrollView)`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const HelpBubbleContainer = Styled.View`
  padding-horizontal: 16px;
`;

const AddRoomButtonSection = Styled.View`
  align-items: flex-end;
  padding-horizontal: 16px;
`;

const AddRoomButton = Styled.Touchable`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-vertical: 8px;
  border-radius: 4px;
  background-color: ${colors.blue.accent};
`;

const DoneButtonContainer = Styled.View`
  padding-horizontal: 16px;
`;

const AddRoomButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.blue.accentDark};
`;

const ListRoomInventoryContent = ({inventory}: any) => {
  const {isOpen, handleOpen, handleClose} = useModal();
  const {params, navigator} = useNavigation();

  return (
    <ContentContainer>
      <InventoryHeader title={'Your rooms'} inventory={inventory} />
      <ProgressBar progressAmount={1 / 3} />
      <Space height={16} />
      <HelpBubbleContainer>
        <HelpBubble>
          {'The more thorough you are with your videos, the more accurate your ' +
            'estimate will be. Remember to show every single item to be moved.'}
        </HelpBubble>
      </HelpBubbleContainer>
      <Space height={12} />
      <Line />
      <Space height={12} />
      <AddRoomButtonSection>
        <AddRoomButton onPress={() => navigator.push('NewRoomInventoryPage', {uuid: params.uuid})}>
          <Space width={12} />
          <Icon color={colors.blue.accentDark} size={14} source={Icon.Plus} />
          <Space width={8} />
          <AddRoomButtonText>Add room</AddRoomButtonText>
          <Space width={12} />
        </AddRoomButton>
      </AddRoomButtonSection>
      <Space height={12} />
      <RoomList rooms={inventory.rooms} />
      <Space height={24} />
      <DoneButtonContainer>
        <LargeButton color={colors.blue.interactive} onPress={handleOpen}>
          <LargeButton.Text color={colors.gray.background}>I'm done recording</LargeButton.Text>
        </LargeButton>
      </DoneButtonContainer>
      <Space height={32} />
      <DoneRecordingConfirmationModal
        isOpen={isOpen}
        onCloseModal={handleClose}
        onContinue={() => {
          handleClose();
          navigator.navigate('AccessPointInventoryPage', {uuid: params.uuid});
        }}
      />
    </ContentContainer>
  );
};

const ListRoomInventoryPage = () => {
  const {params} = useNavigation();

  const {loading, data} = useQuery(ListRoomInventoryPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      uuid: params.uuid,
    },
  });

  return (
    <Loading loading={loading}>
      {() => {
        data.inventory.rooms = data.inventory.rooms.filter(
          (room: any) => room.name !== 'Access Point',
        );
        return <ListRoomInventoryContent inventory={data.inventory} />;
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListRoomInventoryPage.query = gql`
  ${RoomList.fragment}
  ${InventoryHeader.fragment}

  query ListRoomInventoryPage($uuid: String!) {
    ${gql.query}

    inventory(uuid: $uuid) {
      id
      rooms {
        id
        name
        ...RoomList
      }
      ...InventoryHeader
    }
  }
`;

export default ListRoomInventoryPage;
