/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

const HelpContainer = Styled.Touchable`
  height: 36px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${colors.orange.accent}
  border-radius: 8px;
  padding-vertical: 4px;
  padding-horizontal: 8px;
`;

const HelpText = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.orange.status};
`;

const InventoryHelpButton = ({inventory}: any) => {
  const {navigator} = useNavigation();

  return (
    <HelpContainer onPress={() => navigator.navigate('HelpInventoryPage', {uuid: inventory.uuid})}>
      <Icon color={colors.orange.status} size={16} source={Icon.Lightbulb} />
      <Space width={6} />
      <HelpText>Help</HelpText>
    </HelpContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InventoryHelpButton.fragment = gql`
  fragment InventoryHelpButton on Inventory {
    id
    uuid
  }
`;

export default InventoryHelpButton;
