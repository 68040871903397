// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import InventoryReviewForm from '@shared/modules/Inventory/forms/InventoryReviewForm';

// App

const useCreateInventoryReviewMutation = ({inventoryReviewForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      inventoryReviewForm: InventoryReviewForm.toForm(inventoryReviewForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateInventoryReviewMutation.mutation,
    variables: {
      inventoryReviewForm: InventoryReviewForm.toMutation(form.values.inventoryReviewForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateInventoryReviewMutation.mutation = gql`
  mutation useCreateInventoryReviewMutation($inventoryReviewForm: InventoryReviewForm!) {
    response: createInventoryReview(inventoryReviewForm: $inventoryReviewForm) {
      ${gql.errors}
      inventoryReview {
        id
      }
    }
  }
`;

export default useCreateInventoryReviewMutation;
