// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-horizontal: 50px;
`;

const UnauthenticatedIconContainer = Styled.View`
  width: 80px;
  height: 80px;
  align-items: center;
  justify-content: center;
  background-color: ${colors.alpha(colors.red.warning, 0.1)}
  border-radius: 40px;
`;

const UnauthenticatedHeader = Styled.H5`
  color: ${colors.red.warning};
  ${fontWeight(700)}
  text-align: center;
`;

const UnauthenticatedText = Styled.H7`
  text-align: center;
`;

const UnauthenticatedPage = () => {
  return (
    <Container>
      <UnauthenticatedIconContainer>
        <Icon color={colors.red.warning} size={30} source={Icon.ExclamationTriangle} />
      </UnauthenticatedIconContainer>
      <Space height={16} />
      <UnauthenticatedHeader>Unauthenticated User</UnauthenticatedHeader>
      <Space height={16} />
      <UnauthenticatedText>
        Sorry, but we are having trouble signing you in. Please contact your moving company for more
        information.
      </UnauthenticatedText>
    </Container>
  );
};

export default UnauthenticatedPage;
