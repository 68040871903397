/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const PackingOptionTitle = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.black};
`;

const PackingOptionButton = Styled.Touchable`
  padding: 12px;
  border-width: 1px;
  border-radius: 8px;
  border-color: ${(props) => ((props as any).isSelected ? colors.orange.status : colors.gray.border)};
  backgroundColor: ${(props) => ((props as any).isSelected ? colors.orange.accent : colors.white)};
`;

const PackingOptionDescription = Styled.H8`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const PackingOption = ({title, children, value, form, field}: any) => {
  return (
    <PackingOptionButton
      onPress={() => form.setFieldValue(`${field}.packingKind`, value)}
      // @ts-expect-error TS(2769): No overload matches this call.
      isSelected={_.get(form, `values.${field}.packingKind`) === value}
    >
      <PackingOptionTitle>{title}</PackingOptionTitle>
      <Space height={4} />
      <PackingOptionDescription>{children}</PackingOptionDescription>
    </PackingOptionButton>
  );
};

const PackingOptions = ({form, field}: any) => {
  return (
    <React.Fragment>
      <PackingOption title={'Full Packing'} value={'FULL'} form={form} field={field}>
        The moving company will pack everything in your home into boxes.
      </PackingOption>
      <Space height={8} />
      <PackingOption title={'Partial Packing'} value={'PARTIAL'} form={form} field={field}>
        The moving company will pack items you ask for in your home into boxes.
      </PackingOption>
      <Space height={8} />
      <PackingOption title={'No Packing'} value={'NONE'} form={form} field={field}>
        The moving company will only move the boxes you packed.
      </PackingOption>
      <Space height={8} />
      <PackingOption title={'Not Sure'} value={'UNKNOWN'} form={form} field={field}>
        If you want to decide packing after talking to the moving company, select this option.
      </PackingOption>
    </React.Fragment>
  );
};

export default PackingOptions;
