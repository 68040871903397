// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useMountEffect, useMutation, useNavigation, useQuery, useState} from '@supermove/hooks';
import {Storage} from '@supermove/sdk';

// App
import UserAnalyticsTracker from 'modules/App/components/UserAnalyticsTracker';

const AuthenticatedWrapperComponent = ({children, data, refetch}: any) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const {navigator, params} = useNavigation();
  const [handleSubmit] = useMutation(AuthenticatedWrapperComponent.mutation, {
    onCompleted: async ({customerLoginViaText: {token, user, errors}}) => {
      const currentUserId = await Storage.getItem('userId');
      if (errors) {
        if (_.get(_.first(errors), 'field') === 'customer_login_token') {
          return navigator.navigate('UnauthenticatedPage');
        }

        return navigator.navigate('ExpiredPage');
      }
      if (currentUserId !== user.id) {
        await Storage.setItem('token', token);
        await Storage.setItem('userId', user.id);
      }

      // TODO(mark): This reloads the current page without any query params.
      // This will break pages that have query params. We want to remove the `auth` param
      // and that's it. We do not use the navigator because we want hard-refresh the page.
      window.location.href = `${window.location.origin}${window.location.pathname}`;
    },
  });

  /**
   * 1. If URL contains an auth query param, ping the server with it.
   *    -  If authenticated user matches the stored user, do not do anything, navigate
           back to the page without query params.
   *    -  If authenticated user does not match the stored user, persist the
   *       new user into local storage and reload the page without query params.
   * 2. If the URL does not contain an auth query param and there is no valid JWT token
   *    via isAuthenticated, go to the LoginPage.
   * 3. Otherwise, render the children.
   *
   * Note, handleSubmit is asynchronous, so we put setIsInitialized(true) in its own else block
   * so that we don't have a race condition of rendering children before storage set.
   */
  useMountEffect(() => {
    const isAuthenticated = _.get(data, 'isAuthenticated');
    if (params.auth) {
      handleSubmit({variables: {customerLoginToken: params.auth}});
    } else if (!params.auth && !isAuthenticated) {
      navigator.navigate('LoginPage');
    } else {
      setIsInitialized(true);
    }
  });

  return (
    <React.Fragment>
      <UserAnalyticsTracker viewer={data.viewer} />
      {isInitialized && children}
    </React.Fragment>
  );
};

const AuthenticatedWrapper = ({children}: any) => {
  const {loading, data} = useQuery(AuthenticatedWrapper.query, {
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return null;
  }

  return <AuthenticatedWrapperComponent children={children} data={data} />;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AuthenticatedWrapper.query = gql`
  ${UserAnalyticsTracker.fragment}

  query AuthenticatedWrapper {
    ${gql.query}
    isAuthenticated
    viewer {
      id
      ...UserAnalyticsTracker
    }
  }
`;

AuthenticatedWrapperComponent.mutation = gql`
  mutation AuthenticatedWrapperComponent($customerLoginToken: String!) {
    customerLoginViaText(customerLoginToken: $customerLoginToken) {
      ${gql.errors}
      token
      user {
        id
      }
    }
  }
`;

export default AuthenticatedWrapper;
