// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-horizontal: 50px;
`;

const LinkExpiredIconContainer = Styled.View`
  width: 80px;
  height: 80px;
  align-items: center;
  justify-content: center;
  background-color: ${colors.orange.accent}
  border-radius: 40px;
`;

const LinkExpiredHeader = Styled.H5`
  color: ${colors.orange.status};
  ${fontWeight(700)}
  text-align: center;
`;

const LinkExpiredText = Styled.H7`
  text-align: center;
`;

const ExpiredPage = () => {
  return (
    <Container>
      <LinkExpiredIconContainer>
        <Icon color={colors.orange.status} size={30} source={Icon.ExclamationTriangle} />
      </LinkExpiredIconContainer>
      <Space height={16} />
      <LinkExpiredHeader>Link Expired</LinkExpiredHeader>
      <Space height={16} />
      <LinkExpiredText>Sorry, your move portal link is expired.</LinkExpiredText>
      <LinkExpiredText>Please contact your moving company for more information.</LinkExpiredText>
    </Container>
  );
};

export default ExpiredPage;
