/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {Project} from '@supermove/models';

// App
import MultipleChoiceOption from 'components/MultipleChoiceOption';

const OptionsContainer = Styled.View`
  padding-horizontal: 12px;
`;

const MultipleChoiceOptionContainer = Styled.View`
  flex: 0.5;
  flex-direction: row;
`;

const OptionsRow = Styled.View`
  flex-direction: row;
  margin-bottom: 8px;
`;

const BuildingTypes = ({selectedHomeType, setSelectedHomeType, form}: any) => {
  return (
    <OptionsContainer>
      {_.chunk(Project.BUILDING_TYPES, 2).map((buildingTypePair, index) => {
        return (
          <OptionsRow key={index}>
            {buildingTypePair.map((type) => {
              return (
                <MultipleChoiceOptionContainer key={type.name}>
                  <Space width={4} />
                  <MultipleChoiceOption
                    height={60}
                    isSelected={selectedHomeType.name === type.name}
                    onPress={() => {
                      setSelectedHomeType(type);
                      form.setFieldValue('startInventoryProjectForm.projectForm.size', null);
                    }}
                  >
                    {type.name}
                  </MultipleChoiceOption>
                  <Space width={4} />
                </MultipleChoiceOptionContainer>
              );
            })}
          </OptionsRow>
        );
      })}
    </OptionsContainer>
  );
};

export default BuildingTypes;
