const _new = ({userId, inventoryId}: any) => ({
  userId,
  inventoryId,
  rating: null,
  comment: '',
});

const toForm = ({userId, inventoryId, rating, comment}: any) => ({
  userId,
  inventoryId,
  rating,
  comment,
});

const toMutation = ({userId, inventoryId, rating, comment}: any) => ({
  userId,
  inventoryId,
  rating,
  comment,
});

const InventoryReviewForm = {
  new: _new,
  toForm,
  toMutation,
};

export default InventoryReviewForm;
