/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Space, Styled, Swiper, VideoPlayer} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Instruction = Styled.View`
  padding-horizontal: 16px;
`;

const InstructionBody = Styled.View`
  padding: 12px;
  border-width: 1px;
  border-color: ${colors.orange.status};
  border-radius: 8px;
  background-color: ${colors.white};
`;

const NumberedInstruction = Styled.View`
  padding-horizontal: 32px;
`;

const NumberedInstructionBody = Styled.View`
  padding-vertical: 12px;
  padding-right: 12px;
  border-width: 1px;
  border-color: ${colors.orange.status};
  border-radius: 8px;
  background-color: ${colors.white};
  flex-direction: row;
  align-items: center;
`;

const NumberedInstructionText = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.black};
`;

const Text = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.black};
`;

const Bullet = Styled.View`
  height: 24px;
  width: 24px;
  border-radius: 12px;
  margin-left: -12px;
  background-color: ${colors.orange.status};
  align-items: center;
  justify-content: center;
`;

const Number = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const SlideContainer = Styled.View`
  padding-horizontal: 8px;
  flex: 1;
`;

const SlideTitle = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.black};
  padding-horizontal: 8px;
`;

const SlideImagesContainer = Styled.View`
  flex-direction: row;
`;

const SlideImageContainer = Styled.View`
  flex: 1;
  padding-horizontal: 8px;
  border-radius: 8px;
  align-items: center;
`;

const Image = Styled.Image`
  height: 196px;
  width: 144px;
  border-radius: 8px;
`;

const VideoContainer = Styled.View`
  min-height: 180px;
  padding-horizontal: 32px;
`;

const VideoPlayerContainer = Styled.View`
  background-color: ${colors.black};
  border-radius: 4px;
  overflow: hidden;
`;

const VideoPlayerContent = Styled.View`
  flex-direction: row;
  max-height: 320px;
`;

const TutorialStep = ({step, swiperSlides, videoFile, completeTutorial, swiperRef}: any) => {
  switch (step.kind) {
    case 'INSTRUCTION':
      return (
        <Instruction key={step.text}>
          <InstructionBody>
            <Text>{step.text}</Text>
          </InstructionBody>
        </Instruction>
      );
    case 'NUMBERED_INSTRUCTION':
      return (
        <NumberedInstruction key={step.text}>
          <NumberedInstructionBody>
            <Bullet>
              <Number>{step.number}</Number>
            </Bullet>
            <Space width={12} />
            <Text>{step.text}</Text>
          </NumberedInstructionBody>
          <Space height={12} />
        </NumberedInstruction>
      );
    case 'SWIPER':
      return (
        <React.Fragment>
          <Space height={32} />
          <Swiper
            activeDotColor={colors.orange.status}
            swiperRef={swiperRef}
            onEnd={() => step.isCompleteStep && completeTutorial()}
            slides={swiperSlides.map((slide: any) => (
              <SlideContainer>
                <SlideTitle>{slide.title}</SlideTitle>
                <Space height={12} />
                <SlideImagesContainer>
                  {slide.images.map((image: any, index: any) => (
                    <SlideImageContainer key={index}>
                      <Image source={image} />
                    </SlideImageContainer>
                  ))}
                </SlideImagesContainer>
              </SlideContainer>
            ))}
          />
        </React.Fragment>
      );
    case 'VIDEO':
      return (
        <VideoContainer>
          <VideoPlayerContainer>
            <VideoPlayerContent>
              <VideoPlayer
                controls
                url={videoFile}
                onEnd={() => step.isCompleteStep && completeTutorial()}
                width={'100%'}
                height={'100%'}
              />
            </VideoPlayerContent>
          </VideoPlayerContainer>
          <Space height={12} />
        </VideoContainer>
      );
    default:
      return null;
  }
};

const TutorialSteps = ({
  tutorialSteps,
  swiperSlides,
  videoFile,
  completeTutorial,
  swiperRef,
}: any) => {
  return (
    <React.Fragment>
      {tutorialSteps.map((step: any, index: any) => (
        <TutorialStep
          key={index}
          step={step}
          swiperSlides={swiperSlides}
          videoFile={videoFile}
          swiperRef={swiperRef}
          completeTutorial={completeTutorial}
        />
      ))}
    </React.Fragment>
  );
};

export default TutorialSteps;
