/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {FlatList, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

// App
import CheckCircleIcon from 'components/CheckCircleIcon';
import HeaderTitle from 'components/HeaderTitle';

const Container = Styled.View`
  background-color: ${colors.gray.background};
  width: 100%;
  padding-horizontal: 16px;
  padding-vertical: 20px;
`;

const TitleContainer = Styled.View`
  padding-bottom: 16px;
`;

const ItemContainer = Styled.View`
`;

const ItemBorder = Styled.View`
  margin-left: 12px;
  height: 60px;
  border-width: 1px;
  border-color: ${colors.orange.status};
  border-radius: 8px;
  align-items: center;
`;

const ItemContent = Styled.View`
  position: absolute;
  height: 60px;
  width: 100%;
  flex-direction: row;
  align-items: center;
`;

const ItemCountBackground = Styled.View`
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background-color: ${colors.orange.status};
  justify-content: center;
  align-items: center;
`;

const ItemCount = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ItemDescription = Styled.H7`
  ${fontWeight(700)}
  margin-left: 12px;
  color: ${colors.black};
`;

const IconBackground = Styled.View`
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 12;
`;

const ItemSeparator = Styled.View`
  height: 14px;
`;

const TASKS = [
  {id: 1, description: 'Virtual walkthrough', isCompleted: true},
  {id: 2, description: 'Move information', isCompleted: true},
];

const TaskItem = ({item, index}: any) => {
  return (
    <ItemContainer>
      <ItemBorder />
      <ItemContent>
        <ItemCountBackground>
          <ItemCount>{index + 1}</ItemCount>
        </ItemCountBackground>
        <ItemDescription>{item.description}</ItemDescription>
        <IconBackground>
          <CheckCircleIcon isChecked={item.isCompleted} size={20} checkSize={12} />
        </IconBackground>
      </ItemContent>
    </ItemContainer>
  );
};

const PremoveTasks = () => {
  return (
    <Container>
      <TitleContainer>
        <HeaderTitle>Pre-Move</HeaderTitle>
      </TitleContainer>
      <FlatList
        data={TASKS}
        renderItem={TaskItem}
        ItemSeparatorComponent={ItemSeparator}
        keyExtractor={(item: any) => item.id.toString()}
      />
    </Container>
  );
};

export default PremoveTasks;
