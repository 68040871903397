/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';

const UpChevronContainer = Styled.View`
  align-items: center;
  margin-top: -10px;
`;

const UpChevronOuter = Styled.View`
  width: 20px;
  border-left-width: 10px;
  border-right-width: 10px;
  border-bottom-width: 10px;
  border-bottom-color: ${(props) => (props as any).borderColor};
  border-left-color: transparent;
  border-right-color: transparent;
  align-items: center;
`;

const UpChevronInner = Styled.View`
  width: 18px;
  border-left-width: 9px;
  border-right-width: 9px;
  border-bottom-width: 9px;
  border-bottom-color: ${(props) => (props as any).backgroundColor};
  border-left-color: transparent;
  border-right-color: transparent;
  position: absolute;
  top: 2;
`;

const UpChevronOnBorder = ({borderColor, backgroundColor}: any) => {
  return (
    <UpChevronContainer>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <UpChevronOuter borderColor={borderColor}>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <UpChevronInner backgroundColor={backgroundColor} />
      </UpChevronOuter>
    </UpChevronContainer>
  );
};

export default UpChevronOnBorder;
