/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useModal, useNavigation} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import CloseButton from 'components/CloseButton';
import ModalScreenHeader from 'components/ModalScreenHeader';
import AccessPointInventoryTutorialModal from 'modules/Inventory/Help/InventoryTutorial/AccessPointInventoryTutorialModal';
import ClosetInventoryTutorialModal from 'modules/Inventory/Help/InventoryTutorial/ClosetInventoryTutorialModal';
import UploadInventoryTutorialModal from 'modules/Inventory/Help/InventoryTutorial/UploadInventoryTutorialModal';

const Container = Styled.View`
flex: 1;
background-color: ${colors.gray.background};
`;

const HintIconContainer = Styled.View`
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  background-color: ${colors.orange.accent}
  border-radius: 8px;
  margin-right: 12px;
`;

const OptionsContainer = Styled.View`
  padding-horizontal: 16px;
`;

const HelpOptionButton = Styled.Touchable`
  padding: 12px;
  border-width: 1px;
  border-radius: 8px;
  border-color: ${colors.gray.border};
  background-color: ${colors.white};
`;

const HelpOptionText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.black};
`;

const HelpOption = ({onPress, children}: any) => {
  return (
    <HelpOptionButton onPress={onPress}>
      <HelpOptionText>{children}</HelpOptionText>
    </HelpOptionButton>
  );
};

const HelpInventoryPage = () => {
  const {navigator} = useNavigation();
  const uploadInventoryTutorialModal = useModal();
  const closetInventoryTutorialModal = useModal();
  const accessPointInventoryTutorialModal = useModal();

  return (
    <Container>
      <ModalScreenHeader
        title={'Help'}
        onClose={() => navigator.goBack()}
        left={
          <HintIconContainer>
            <Icon color={colors.orange.status} size={16} source={Icon.Lightbulb} />
          </HintIconContainer>
        }
        right={<CloseButton onPress={() => navigator.goBack()} />}
      />
      <Space height={16} />
      <OptionsContainer>
        <HelpOption onPress={uploadInventoryTutorialModal.handleOpen}>
          How to record a room
        </HelpOption>
        <Space height={10} />
        <HelpOption onPress={closetInventoryTutorialModal.handleOpen}>
          How to take closet photos
        </HelpOption>
        <Space height={10} />
        <HelpOption onPress={accessPointInventoryTutorialModal.handleOpen}>
          How to take access point photos
        </HelpOption>
      </OptionsContainer>
      <UploadInventoryTutorialModal
        isOpen={uploadInventoryTutorialModal.isOpen}
        onClose={uploadInventoryTutorialModal.handleClose}
      />
      <ClosetInventoryTutorialModal
        isOpen={closetInventoryTutorialModal.isOpen}
        onClose={closetInventoryTutorialModal.handleClose}
      />
      <AccessPointInventoryTutorialModal
        isOpen={accessPointInventoryTutorialModal.isOpen}
        onClose={accessPointInventoryTutorialModal.handleClose}
      />
    </Container>
  );
};

export default HelpInventoryPage;
