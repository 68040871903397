/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

// App
import UpChevronOnBorder from 'components/UpChevronOnBorder';

const Container = Styled.View`
  background-color: ${colors.white};
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 8px;
`;

const ContentContainer = Styled.View`
  flex-direction: row;
  padding-vertical: 12px;
`;

const HelpText = Styled.H7`
  color: ${colors.black};
  ${fontWeight(500)}
`;

const HelpBubble = ({children}: any) => {
  return (
    <Container>
      <UpChevronOnBorder borderColor={colors.gray.border} backgroundColor={colors.white} />
      <ContentContainer>
        <Space width={16} />
        <Icon color={colors.orange.status} size={16} source={Icon.Lightbulb} />
        <Space width={12} />
        <HelpText>{children}</HelpText>
        <Space width={16} />
      </ContentContainer>
    </Container>
  );
};

export default HelpBubble;
