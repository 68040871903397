/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import FileViewer from '@shared/modules/File/components/FileViewer';
import useCheckMuxVideoIsReady from '@shared/modules/File/hooks/useCheckMuxVideoIsReady';

const VideoContainer = Styled.View`
  min-height: 180px;
`;

const VideoPlayerContainer = Styled.View`
  align-items: center;
  background-color: ${colors.black};
  overflow: hidden;
  width: ${(props) => ((props as any).width ? `${(props as any).width}px` : 'auto')}
`;

const VideoPlayerContent = Styled.View`
  flex-direction: row;
  max-height: 320px;
`;

const LoadingContainer = Styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  border-color: ${colors.gray.border};
  border-width: 1px;
  border-radius: 4px;
`;

const Indicator = Styled.Loading`
`;

const VideoLoading = () => {
  return (
    <VideoContainer>
      <LoadingContainer>
        <Indicator size={'small'} color={colors.gray.secondary} />
      </LoadingContainer>
    </VideoContainer>
  );
};

const VideoViewer = ({file, width, height, style}: any) => {
  const {isReady} = useCheckMuxVideoIsReady({url: file.playbackUrl});

  return (
    <VideoContainer>
      {isReady ? (
        <VideoPlayerContainer width={width} style={style}>
          <VideoPlayerContent>
            <FileViewer file={file} height={height || '100%'} width={'100%'} />
          </VideoPlayerContent>
        </VideoPlayerContainer>
      ) : (
        <VideoLoading />
      )}
    </VideoContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
VideoViewer.fragment = gql`
  ${FileViewer.fragment}

  fragment VideoViewer on File {
    id
    playbackUrl
    ...FileViewer
  }
`;

export default VideoViewer;
