// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {LocationInput, Space, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';
import {focus} from '@supermove/utils';

// App
import LocationForm from '@shared/modules/Location/forms/LocationForm';
import FieldWithLabel from 'components/FieldWithLabel';

const Row = Styled.View`
  flex-direction: row;
`;

const FieldContainer = Styled.View`
  flex: 1;
`;

const PickUpLabel = Styled.H8`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  margin-top: -4;
`;

const DropOffLabel = Styled.H8`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const AddressField = ({form, locationFormField, unitRef, index}: any) => {
  return (
    <FieldWithLabel
      {...form}
      index={index}
      component={LocationInput}
      name={`${locationFormField}.address`}
      label={'Address'}
      placeholder={'Address'}
      input={{
        setFieldValue: form.setFieldValue,
        setFieldError: form.setFieldError,
        setFieldTouched: form.setFieldTouched,
        locationFormField,
        onSelect: () => {
          focus(unitRef);
        },
        onLocation: (location: any) => {
          const locationForm = _.get(form.values, locationFormField);
          form.setFieldValue(
            locationFormField,
            LocationForm.handleLocationInput(locationForm, {location}),
          );
        },
        style: {
          backgroundColor: 'transparent',
          borderWidth: 1,
          borderColor: colors.gray.border,
          paddingTop: 14,
          height: 48,
          fontWeight: '500',
          marginTop: 0,
        },
      }}
      style={{backgroundColor: colors.gray.background}}
    />
  );
};

const LocationFields = ({form, field}: any) => {
  const pickupUnitRef = React.createRef();
  const dropoffUnitRef = React.createRef();

  return (
    <React.Fragment>
      <PickUpLabel>Pick up location</PickUpLabel>
      <Space height={8} />
      <AddressField
        index={1}
        form={form}
        locationFormField={`${field}.locationForms.0`}
        unitRef={pickupUnitRef}
      />
      <Space height={8} />
      <Row>
        <FieldContainer>
          <FieldWithLabel
            {...form}
            name={`${field}.locationForms.0.unit`}
            label={'Unit #'}
            input={{placeholder: 'Unit #', ref: pickupUnitRef}}
          />
        </FieldContainer>
        <Space width={8} />
        <FieldContainer>
          <FieldWithLabel
            {...form}
            name={`${field}.locationForms.0.zipCode`}
            label={'Zipcode'}
            input={{placeholder: 'Zipcode', disabled: true}}
          />
        </FieldContainer>
      </Row>
      <Space height={12} />
      <DropOffLabel>Drop off location</DropOffLabel>
      <Space height={8} />
      <AddressField
        index={2}
        form={form}
        locationFormField={`${field}.locationForms.1`}
        unitRef={dropoffUnitRef}
      />
      <Space height={8} />
      <Row>
        <FieldContainer>
          <FieldWithLabel
            {...form}
            name={`${field}.locationForms.1.unit`}
            label={'Unit #'}
            input={{placeholder: 'Unit #', ref: dropoffUnitRef}}
          />
        </FieldContainer>
        <Space width={8} />
        <FieldContainer>
          <FieldWithLabel
            {...form}
            name={`${field}.locationForms.1.zipCode`}
            label={'Zipcode'}
            input={{placeholder: 'Zipcode', disabled: true}}
          />
        </FieldContainer>
      </Row>
    </React.Fragment>
  );
};

export default LocationFields;
