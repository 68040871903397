// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = ({inventoryId}: any) => ({
  inventoryId,
  accessPointHasElevator: false,
  accessPointHasStairs: false,
  accessPointHasNone: false,
  accessPointWalkTime: '',
  accessPointWalkTimeDetails: '',

  // Private
  isUploadingPhoto: false,
});

const edit = withFragment(
  (inventory) => ({
    inventoryId: (inventory as any).id,
    accessPointHasElevator: (inventory as any).accessPointHasElevator,
    accessPointHasStairs: (inventory as any).accessPointHasStairs,
    accessPointHasNone: false,
    accessPointWalkTime: (inventory as any).accessPointWalkTime,
    accessPointWalkTimeDetails: (inventory as any).accessPointWalkTimeDetails,
    // Private
    isUploadingPhoto: false,
  }),
  gql`
    fragment CustomerInventoryForm_edit on Inventory {
      id
      accessPointHasElevator
      accessPointHasStairs
      accessPointWalkTime
      accessPointWalkTimeDetails
    }
  `,
);

const toForm = ({
  inventoryId,
  accessPointHasElevator,
  accessPointHasStairs,
  accessPointHasNone,
  accessPointWalkTime,
  accessPointWalkTimeDetails,

  // Private
  isUploadingPhoto,
}: any) => ({
  inventoryId,
  accessPointHasElevator,
  accessPointHasStairs,
  accessPointHasNone,
  accessPointWalkTime,
  accessPointWalkTimeDetails,
  isUploadingPhoto,
});

const toMutation = ({
  inventoryId,
  accessPointHasElevator,
  accessPointHasStairs,
  accessPointWalkTime,
  accessPointWalkTimeDetails,
}: any) => ({
  inventoryId,
  accessPointHasElevator,
  accessPointHasStairs,
  accessPointWalkTime,
  accessPointWalkTimeDetails,
});

const CustomerInventoryForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default CustomerInventoryForm;
