/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

const Background = Styled.View`
  height: ${(props) => `${(props as any).size}px`};
  width: ${(props) => `${(props as any).size}px`};
  border-radius: 10px;
  background-color: ${(props) => ((props as any).isChecked ? colors.green.status : colors.blue.accent)};
  justify-content: center;
  align-items: center;
`;

const CheckCircleIcon = ({isChecked, size, checkSize}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Background isChecked={isChecked} size={size}>
      <Icon color={colors.white} size={checkSize} source={Icon.Check} />
    </Background>
  );
};

export default CheckCircleIcon;
