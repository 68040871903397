/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useState, useQuery} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import CompleteInventoryProjectForm from '@shared/modules/Inventory/forms/CompleteInventoryProjectForm';
import useCompleteInventoryProjectMutation from '@shared/modules/Inventory/hooks/useCompleteInventoryProjectMutation';
import HelpBubble from 'components/HelpBubble';
import LargeButton from 'components/LargeButton';
import Line from 'components/Line';
import ProgressBar from 'components/ProgressBar';
import CustomerInformationFields from 'modules/Inventory/Finish/components/CustomerInformationFields';
import LocationFields from 'modules/Inventory/Finish/components/LocationFields';
import MoveDatePreferenceFields from 'modules/Inventory/Finish/components/MoveDatePreferenceFields';
import PackingOptions from 'modules/Inventory/Finish/components/PackingOptions';
import InventoryHeader from 'modules/Inventory/components/InventoryHeader';

const Container = Styled.View`
  background-color: ${colors.gray.background}
  flex: 1;
`;

const HelpBubbleContainer = Styled.View`
  padding-horizontal: 16px;
  padding-top: 16px;
  padding-bottom: 12px;
  background-color: ${colors.white};
`;

const PaddedContainer = Styled.View`
  padding-horizontal: 16px;
`;

const SectionContainer = Styled.View`
  padding-horizontal: 16px;
  background-color: ${colors.white};
  border-radius: 8px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  zIndex: ${(props) => 100 - (props as any).index}
`;

const SectionHeaderContainer = Styled.View`
  flex-direction: row;
  padding-vertical: 20px;
  justify-content: space-between;
  align-items: center;
`;

const SectionTitle = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.black};
`;

const SectionHeaderRightSide = Styled.View`
  flex-direction: row;
  align-items: center
`;

const IsCompleteIconContainer = Styled.View`
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: ${(props) => ((props as any).isComplete ? colors.green.status : colors.gray.border)};
  align-items: center;
  justify-content: center;
`;

const SectionVisibilityButton = Styled.Touchable`
  padding-vertical: 4px;
  border-radius: 20px;
  background-color: ${(props) => ((props as any).isShowing ? colors.blue.interactive : colors.blue.accent)};
  width: 48px;
  align-items: center;
`;

const SectionVisibilityText = Styled.H8`
  ${fontWeight(700)}
  color: ${(props) => ((props as any).status === 'showing' ? colors.white : colors.blue.accentDark)};
`;

const ActivityIndicator = Styled.Loading`
`;

const Section = ({children, title, isComplete, index}: any) => {
  const [isShowing, setIsShowing] = useState(true);

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <SectionContainer index={index}>
      <SectionHeader isComplete={isComplete} isShowing={isShowing} setIsShowing={setIsShowing}>
        {title}
      </SectionHeader>
      {isShowing && (
        <React.Fragment>
          {children}
          <Space height={20} />
        </React.Fragment>
      )}
    </SectionContainer>
  );
};

const SectionHeader = ({children, isComplete, isShowing, setIsShowing}: any) => {
  return (
    <SectionHeaderContainer>
      <SectionTitle>{children}</SectionTitle>
      <SectionHeaderRightSide>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <IsCompleteIconContainer isComplete={isComplete}>
          <Icon source={Icon.Check} size={12} color={colors.white} />
        </IsCompleteIconContainer>
        <Space width={8} />
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <SectionVisibilityButton onPress={() => setIsShowing(!isShowing)} isShowing={isShowing}>
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <SectionVisibilityText status={isShowing ? 'showing' : 'hidden'}>
            {isShowing ? 'Hide' : 'Show'}
          </SectionVisibilityText>
        </SectionVisibilityButton>
      </SectionHeaderRightSide>
    </SectionHeaderContainer>
  );
};

const FinishInventoryPageContent = ({inventory, viewer}: any) => {
  const {navigator, params} = useNavigation();

  const completeInventoryProjectForm = CompleteInventoryProjectForm.edit(inventory, {
    creatorId: viewer.id,
  });
  const {form, submitting, handleSubmit} = useCompleteInventoryProjectMutation({
    completeInventoryProjectForm,
    onSuccess: () => {
      navigator.navigate('NewInventoryReviewInventoryPage', {
        uuid: params.uuid,
      });
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  const jobFormsPath = `completeInventoryProjectForm.projectForm.jobForms`;
  const indexForJob = `${_.get(form, `values.${jobFormsPath}.length`) - 1}`;
  const jobFormFieldPath = `${jobFormsPath}.${indexForJob}`;
  const customerFormFieldPath = `${jobFormFieldPath}.customerForm`;
  const jobForm = _.get(form, `values.${jobFormFieldPath}`);
  const {customerForm} = jobForm;
  const pickUpLocation = jobForm.locationForms[0];
  const isCustomerInformationComplete =
    !!_.get(customerForm, 'names.0') &&
    !!_.get(customerForm, 'names.1') &&
    !!_.get(customerForm, 'phoneNumber') &&
    !!_.get(customerForm, 'email');
  const isMoveDatePreferenceComplete =
    !!_.get(jobForm, 'date') || !!_.get(jobForm, 'isDateFlexible');
  const isLocationComplete =
    !!_.get(pickUpLocation, 'address') && !!_.get(pickUpLocation, 'zipCode');
  const isPackingComplete = !!_.get(jobForm, 'packingKind');

  return (
    <Container>
      <ScrollView>
        <InventoryHeader title={'Virtual Walkthrough'} inventory={inventory} />
        <ProgressBar progressAmount={3 / 3} />
        <HelpBubbleContainer>
          <HelpBubble>{'Before you leave, please confirm your move information.'}</HelpBubble>
        </HelpBubbleContainer>
        <Line />
        <PaddedContainer>
          <Space height={16} />
          <Section
            title={'1. Customer Information'}
            isComplete={isCustomerInformationComplete}
            index={0}
          >
            <CustomerInformationFields form={form} field={customerFormFieldPath} />
          </Section>
          <Space height={12} />
          <Section
            title={'2. Move Date Preference'}
            isComplete={isMoveDatePreferenceComplete}
            index={1}
          >
            <MoveDatePreferenceFields form={form} field={jobFormFieldPath} jobForm={jobForm} />
          </Section>
          <Space height={12} />
          <Section title={'3. Location'} isComplete={isLocationComplete} index={2}>
            <LocationFields form={form} field={jobFormFieldPath} />
          </Section>
          <Space height={12} />
          <Section title={'4. Packing'} isComplete={isPackingComplete} index={3}>
            <PackingOptions form={form} field={jobFormFieldPath} />
          </Section>
          <Space height={24} />
          <LargeButton
            onPress={handleSubmit}
            disabled={
              !isCustomerInformationComplete ||
              !isMoveDatePreferenceComplete ||
              !isLocationComplete ||
              !isPackingComplete ||
              submitting
            }
          >
            {submitting ? (
              <ActivityIndicator color={colors.white} />
            ) : (
              <LargeButton.Text color={colors.gray.background}>Submit</LargeButton.Text>
            )}
          </LargeButton>
          <Space height={32} />
        </PaddedContainer>
      </ScrollView>
    </Container>
  );
};

const FinishInventoryPage = () => {
  const {params} = useNavigation();
  const {loading, data} = useQuery(FinishInventoryPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      uuid: params.uuid,
    },
  });

  return (
    <Loading loading={loading}>
      {() => <FinishInventoryPageContent inventory={data.inventory} viewer={data.viewer} />}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
FinishInventoryPage.query = gql`
  ${CompleteInventoryProjectForm.edit.fragment}
  ${InventoryHeader.fragment}

  query FinishInventoryPage($uuid: String!) {
    ${gql.query}
    viewer {
      id
    }
    inventory(uuid: $uuid) {
      id
      ...CompleteInventoryProjectForm_edit
      ...InventoryHeader
    }
  }
`;

export default FinishInventoryPage;
