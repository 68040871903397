// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {AnalyticsContext} from '@supermove/analytics';
import {Lifecycle} from '@supermove/components';
import {gql} from '@supermove/graphql';

/**
 * Called on app start when we first get the user.
 */
const trackUser = ({analytics, viewer}: any) => {
  const user = {
    // These keys should be camelcase to signify specific keys on
    // analytics services.
    id: _.get(viewer, 'id', ''),
    firstName: _.get(viewer, 'firstName', ''),
    lastName: _.get(viewer, 'lastName', ''),
    email: _.get(viewer, 'email', ''),
    phoneNumber: _.get(viewer, 'phoneNumber', ''),
    role: _.get(viewer, 'role', ''),
    organizationSlug: _.get(viewer, 'organization.slug', ''),
    companyName: _.get(viewer, 'organization.company.name', ''),
    companyIdentifier: _.get(viewer, 'organization.company.slug', ''),
    companyCity: _.get(viewer, 'organization.company.locationCity', ''),
    companyState: _.get(viewer, 'organization.company.locationState', ''),
    companyCountry: _.get(viewer, 'organization.company.locationCountry', ''),
    companyContractedTruckQuantity: _.get(
      viewer,
      'organization.company.contractedTruckQuantity',
      '',
    ),
    companySize: _.get(viewer, 'organization.company.size', ''),
    companySalesforceId: _.get(viewer, 'organization.company.salesforceId', ''),

    // These key names are passed directly to the payload for analytics.
    Role: _.get(viewer, 'role', ''),
    Organization: _.get(viewer, 'organization.slug', ''),
  };

  analytics.identify(user);
};

const UserAnalyticsTracker = ({viewer}: any) => {
  return (
    <AnalyticsContext.Consumer>
      {(analytics) => (
        <Lifecycle
          viewer={viewer}
          onMount={async () => {
            trackUser({analytics, viewer});
          }}
          onUpdate={async (previousProps: any) => {
            if (_.get(previousProps, 'viewer.id') !== _.get(viewer, 'id')) {
              trackUser({analytics, viewer});
            }
          }}
        />
      )}
    </AnalyticsContext.Consumer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UserAnalyticsTracker.fragment = gql`
  fragment UserAnalyticsTracker on User {
    id
    firstName
    lastName
    email
    phoneNumber
    role
    organization {
      id
      slug
      company {
        id
        name
        slug
        locationCity
        locationState
        locationCountry
        contractedTruckQuantity
        size
        salesforceId
      }
    }
  }
`;

export default UserAnalyticsTracker;
