// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import CompleteInventoryProjectForm from '@shared/modules/Inventory/forms/CompleteInventoryProjectForm';

// App

const useCompleteInventoryProjectMutation = ({
  completeInventoryProjectForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      completeInventoryProjectForm: CompleteInventoryProjectForm.toForm(
        completeInventoryProjectForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCompleteInventoryProjectMutation.mutation,
    variables: {
      completeInventoryProjectForm: CompleteInventoryProjectForm.toMutation(
        form.values.completeInventoryProjectForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useCompleteInventoryProjectMutation.mutation = gql`
  mutation useCompleteInventoryProjectMutation($completeInventoryProjectForm: CompleteInventoryProjectForm!) {
    response: completeInventoryProject(completeInventoryProjectForm: $completeInventoryProjectForm) {
      ${gql.errors}
      inventory {
        id
      }
    }
  }
`;

export default useCompleteInventoryProjectMutation;
