/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
  z-index: ${(props) => 100 - (props as any).index};
  background-color: ${colors.gray.border};
  border-radius: 4px;
`;

const Input = Styled.TextInput.H7`
  background-color: ${colors.gray.background};
  border-width: 1px;
  border-color: ${colors.gray.border}
  padding-horizontal: 16px;
  padding-top: 22px;
  padding-bottom: 8px;
  height: 48px;
  ${fontWeight(500)}
`;

const InputLabel = Styled.Text`
  font-size: 10px;
  line-height: 14px;
  ${fontWeight(700)}
  color: ${colors.gray.tertiary};
  position: absolute;
  left: 13px;
  top: 8px;
`;

const ValidationError = Styled.H7`
  margin-top: 5px;
  color: ${colors.red.warning};
`;

const Placeholder = Styled.Text`
  ${fontWeight(500)}
  color: ${colors.gray.tertiary};
  position: absolute;
  bottom: 8px;
  left: 12px;
`;

const FieldWithLabel = ({
  index = 0,
  name,
  touched,
  errors,
  values,
  component: InputComponent = Input,
  input = {},
  handleBlur,
  handleChange,
  label,
  style,
  placeholder,
}: any) => {
  const value = _.get(values, name);

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Container index={index} style={style}>
      {!value ? null : <InputLabel>{label}</InputLabel>}
      <InputComponent
        {...input}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholderTextColor={colors.gray.tertiary}
      />
      {/* Inputs such as PhoneInput do not take placeholderTextColor, so this provides an alternative */}
      {placeholder && !value && <Placeholder>{placeholder}</Placeholder>}
      {_.get(touched, name) && _.get(errors, name) && (
        <ValidationError>{_.get(errors, name)}</ValidationError>
      )}
    </Container>
  );
};

export default FieldWithLabel;
