/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// App
import OrganizationPhoto from 'components/OrganizationPhoto';
import UpChevronOnBorder from 'components/UpChevronOnBorder';

const Container = Styled.View`
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 8px;
  padding-horizontal: 16px;
`;

const ProfileContainer = Styled.View`
  align-items: center;
`;

const Name = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.black}
`;

const CommentContainer = Styled.View`
  background-color: ${colors.orange.accent};
  border-width: 1px;
  border-color: ${colors.orange.status};
  padding-horizontal: 12px;
  border-radius: 8px;
`;

const CommentText = Styled.H7`
  color: ${colors.black};
  ${fontWeight(500)}
`;

const OrganizationMessage = ({organization}: any) => {
  return (
    <Container>
      <Space height={16} />
      <ProfileContainer>
        <OrganizationPhoto
          organization={organization}
          style={{
            height: 72,
            width: 72,
            borderRadius: 36,
            borderWidth: 2,
            borderColor: colors.orange.status,
          }}
        />
        <Space height={12} />
        <Name>{organization.name}</Name>
      </ProfileContainer>
      <Space height={16} />
      <CommentContainer>
        <UpChevronOnBorder
          borderColor={colors.orange.status}
          backgroundColor={colors.orange.accent}
        />
        <Space height={12} />
        <CommentText>
          Thank you for completing your virtual walkthrough! Our team will review it and get back to
          you with an estimate as soon as possible. You may view your move portal on the next page.
        </CommentText>
        <Space height={12} />
      </CommentContainer>
      <Space height={16} />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationMessage.fragment = gql`
  ${OrganizationPhoto.fragment}

  fragment OrganizationMessage on Organization {
    id
    name
    ...OrganizationPhoto
  }
`;

export default OrganizationMessage;
