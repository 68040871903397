// Libraries
import React from 'react';

import {createBrowserApp} from '@supermove/navigation';
import {ExecutionEnvironment} from '@supermove/sdk';

// Components
import CustomerApp from './CustomerApp';
import Router from './Router';

// Assets
import '@supermove/styles/css/Global.css';
import '@supermove/styles/css/Avenir.css';

// canUseDOM check is a workaround to avoid Gatsby errors when it attempts SSR builds
const AppWrapper = (props: any) =>
  ExecutionEnvironment.canUseDOM ? <CustomerApp router={createBrowserApp(Router)} /> : null;

export default AppWrapper;
