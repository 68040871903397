/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {PhoneInput, Space} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import FieldWithLabel from 'components/FieldWithLabel';

const CustomerInformationFields = ({form, field}: any) => {
  const customerFirstNameField = `${field}.names.0`;
  const customerLastNameField = `${field}.names.1`;

  return (
    <React.Fragment>
      <FieldWithLabel
        {...form}
        name={customerFirstNameField}
        label={'First Name'}
        input={{
          placeholder: 'First Name',
        }}
      />
      <Space height={8} />
      <FieldWithLabel
        {...form}
        name={customerLastNameField}
        label={'Last Name'}
        input={{
          placeholder: 'Last Name',
        }}
      />
      <Space height={8} />
      <FieldWithLabel
        {...form}
        component={PhoneInput}
        name={`${field}.phoneNumber`}
        label={'Phone Number'}
        placeholder={'Phone Number'}
        input={{
          setFieldValue: form.setFieldValue,
          style: {
            height: 48,
            paddingTop: 16,
            fontWeight: '500',
            backgroundColor: colors.gray.background,
            borderWidth: 1,
            borderColor: colors.gray.border,
          },
        }}
      />
      <Space height={8} />
      <FieldWithLabel
        {...form}
        name={`${field}.email`}
        label={'Email'}
        input={{
          placeholder: 'Email',
        }}
      />
    </React.Fragment>
  );
};

export default CustomerInformationFields;
