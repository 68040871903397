/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {useNavigation} from '@supermove/hooks';
import {colors} from '@supermove/styles';

const Touchable = Styled.Touchable`
`;

const BackButton = () => {
  const {navigator} = useNavigation();

  return (
    <Touchable onPress={() => navigator.goBack()}>
      <Icon color={colors.gray.primary} size={20} source={Icon.ArrowLeft} />
    </Touchable>
  );
};

export default BackButton;
