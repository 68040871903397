/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

const Container = Styled.View`
  background-color: ${colors.gray.border};
  flex-direction: row;
`;

const Progress = Styled.View`
  background-color: ${colors.green.status};
  height: 8px;
  flex: ${(props) => (props as any).progressAmount};
  border-radius: 20px;
`;

const ProgressBar = ({progressAmount}: any) => {
  return (
    <Container>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Progress progressAmount={progressAmount} />
    </Container>
  );
};

export default ProgressBar;
